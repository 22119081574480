import * as Sentry from "@sentry/gatsby";
import * as selectors from "@state/selectors";

import { BrowserQRCodeReader, IScannerControls } from "@zxing/browser";
import QrScanner from "qr-scanner";
import { useReduxState } from "re-reduced";
import { useEffect, useRef, useState } from "react";
import { checkForIOS } from "./helper";
import { log, useTelemetry } from "./telemertry";



type InitFunction = (error: Error | undefined, controls: QrScanner | IScannerControls | undefined) => void;
type ScanResultFunction = (result:string) => void;

const captureError = (error: Error, type: "qr-scanner" | "@zxing/browser") => {
  Sentry.captureException(error, {
    tags: {
      scanner: type
    }
  })
}


export const useQRScanner = (videoEl: HTMLVideoElement | undefined) => {
  
  const { cameraAllowed } = useReduxState({
    cameraAllowed: selectors.getCameraPermission,
  });
  
  const controller = useRef<QrScanner | IScannerControls>();
  const [isIOS] = useState(checkForIOS());
  const [initialised, setInitialised] = useState<boolean>();
  const [scanResult, setScanResult] = useState<string | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const track = useTelemetry();

  log("scanner: initialised", initialised)
  

  const start = () => {
    if (isIOS) {
    } else {
      controller.current && (controller.current as QrScanner).start().catch(error => captureError(error, 'qr-scanner'));
    }
  }

  const stop = () => {
    log('scanner: stop')
    track("Camera instance stopped", { cameraType: isIOS ? "browser" : "nimiq" });
    controller.current && controller.current.stop();
  }

  const pause = () => {
    log('scanner: pause')
    track("Camera instance paused", { cameraType: isIOS ? "browser" : "nimiq" });
    setIsPaused(true);
  }

  const resume = () => {
    log('scanner: resume')
    track("Camera instance resumed", { cameraType: isIOS ? "browser" : "nimiq" });
    setIsPaused(false);
  }

  const destroy = () => {
    // log('scanner: destroy', initialised)
    // if (!initialised) return;

    log('scanner: destroy')
    
    stop();

    if (!isIOS) {
      log("scanner: QrScanner destroy", controller.current)
      controller.current && (controller.current as QrScanner).destroy();
    }

    track("Camera instance destroyed", { cameraType: isIOS ? "browser" : "nimiq" });

    controller.current = undefined;
  }

  const initialise = (videoEl: HTMLVideoElement) => {
    
    if(videoEl && !initialised) {

      setInitialised(true);

      log('scanner: init')

      track("Camera initialising", { cameraType: isIOS ? "browser" : "nimiq" });

      const onInit: InitFunction = (error, controls) => {
        if (error) {     
          log('scanner: error', error)
          track("Camera initialise error", { error: error?.message });
          setError(true)
        } else if (controls) {
          log('scanner: controls')
          track("Camera initialised");
          controller.current = controls;
          setReady(true)
        }
      }

      const onScanResult: ScanResultFunction = (result: string) => {
        log('scanner: result')
        !isPaused && setScanResult(result);
      }
      
      if (isIOS) {
        loadBrowserQRCodeReader(videoEl, onScanResult, onInit);
      } else {
        loadNimiqScanner(videoEl, onScanResult, onInit);
      }
    }
  }

  useEffect(() => {
  
    track("Attempting to initialise camera", { initialised, cameraAllowed });

    if (videoEl && cameraAllowed) {
      initialise(videoEl)
    }

    return () => {
      log("scanner: useEffect cleanup")
      destroy()
    };
  }, [videoEl, cameraAllowed]);

  return {
    initialise,
    isReady: ready,
    stop,
    start,
    error,
    pause,
    resume,
    destroy,
    scanResult,
    setScanResult
  }
}


const loadNimiqScanner = async (
  videoEl: HTMLVideoElement,
  onScanResult: ScanResultFunction,
  onInit: InitFunction
) => {

  try {

    // TODO: why does this value not work with the QrScanner `maxScansPerSecond` option?
    const scanRate = (process.env.GATSBY_SCAN_RATE ?? 25) as number;

    log("scanner: scan rate set at:", scanRate)

    const qrScanner = new QrScanner(videoEl, (result) => {
      if (result) {
        onScanResult(result.data);
      }
    }, {
      // onDecodeError: log,
      preferredCamera: "environment",
      maxScansPerSecond: 10,
      highlightScanRegion: true
    })

    await qrScanner.start();

    onInit(undefined, qrScanner);
  } catch(error) {
    captureError(error as Error, 'qr-scanner');
    onInit(error as Error, undefined)
  }
};

const loadBrowserQRCodeReader = async (
  videoEl: HTMLVideoElement,
  onScanResult: ScanResultFunction,
  onInit: InitFunction
  ) => {
  try {
    const controls = await new BrowserQRCodeReader()
        .decodeFromConstraints({
            video: {
              facingMode: "environment",
            },
          },
          videoEl,
          (result, _error, _controls) => {
            if (result) {
              // @ts-ignore
              onScanResult(result.text);
            }
          }
        )
    onInit(undefined, controls);
  } catch(error) {
    captureError(error as Error, '@zxing/browser');
    onInit(error as Error, undefined)
  }
};
